<template>
	<div class="main-content">
		<div class="content-box" v-loading='loading'>
			<!-- 浏览 -->
			<div class="browse-box">
				<div class="top">
					<img class="top-bg" src='https://cdn.dkycn.cn/images/melyshop/pc-mallHomeBg.png' />
					<div class="top-title">页面标题</div>
				</div>
				<div class="nav" :class="navEditShow?'browse-border':''">
					<div class="nav-footer">
						<div class="item" :key="item.Id" v-for="(item,index) in navList" @click="clickIndex=index">
							<div class="img-box">
								<img v-show="item.ImgUrl&&clickIndex!=index" :src="imgUrl + item.ImgUrl" class="upload-avatar" />
								<img v-show="item.HighlightImgUrl&&clickIndex==index" :src="imgUrl + item.HighlightImgUrl" class="upload-avatar" />
							</div>
							<div :style="{ color: clickIndex == index ? themeColor : '#666' }">{{item.Name}}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 编辑 -->
			<div class="edit-box" style="margin-left:25px;">
				<div class="nav item">
					<!-- <div class="icon-box" style="width:30px;height:30px;" @click="handleNavShow">
						<i class="el-icon-setting" style="color:#409eff"></i>
					</div> -->
					<div class="dialog-box" v-if='navEditShow' style="margin-bottom: 100px;">
						<!-- <div class="close-box">
							<i class="el-icon-close" @click="handleNavShow"></i>
						</div> -->
						<div class="dialog-top">
							<span class="dialog-title">底部导航配置</span>
						</div>

						<div class="tab-mark">长按导航可拖动排序</div>
						<div class="tab-mark">图标建议尺寸长80px，高80px;透明背景</div>
						<div class="list-wrap" style="width:100%;margin-bottom: 25px;">
							<draggable style='width:100%;' v-model="navList" :options="{
									animation: 150,
									ghostClass: 'sortable-ghost',
									chosenClass: 'chosenClass',
									scroll: true,
									scrollSensitivity: 200
								}">
								<div class="tab-item" v-for="(item,index) in navList" :key="index">
									<div class="tab-edit">

										<div class="title">
											<span style="margin-right:10px;">工具名称：</span>
											<el-input v-model="item.Name" maxlength="4" style="width:140px;height:30px;"></el-input>
										</div>

										<div class="icon" style="margin-top: 10px;" @click="handleMark(index)">
											<span style="margin-right:10px;">图标：</span>

											<div class="icon-upload">
												<span>普通展示</span>
												<my-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleAvatarSuccess"
												 list-type="picture-card" style="margin-right:10px;">
													<img v-if="item.ImgUrl" :src="imgUrl + item.ImgUrl" class="upload-avatar" />
													<i v-else class="el-icon-plus"></i>
												</my-upload>
											</div>

											<div class="icon-upload">
												<span>高亮展示</span>
												<my-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleSelAvatarSuccess"
												 list-type="picture-card">
													<img v-if="item.HighlightImgUrl" :src="imgUrl + item.HighlightImgUrl" class="upload-avatar" />
													<i v-else class="el-icon-plus"></i>
												</my-upload>
											</div>

										</div>

										<div class="select-link">
											<span style="margin-right:10px;">链接：</span>

											<div class="link-name">
												<div class="page-name" @click="showNavModal(index)">
													<span v-show="!item.PageName">请选择页面</span>
													<span v-show="item.PageName">{{item.PageName}}</span>
												</div>
												<i class="el-icon-arrow-down" @click="showNavModal(index)"></i>
												<i class="el-icon-close" @click="deleteNavLink(index)" v-show="item.PageName"></i>
											</div>

										</div>
									</div>
									<el-button @click="handleDeleteNav(index)" type="text" style="color:#f46b6b;margin-left:10px;">删除</el-button>
								</div>

							</draggable>
						</div>
						<div class="tab-add" v-if="navList.length<5">
							<div style="cursor:pointer" @click="handleAddNav">
								<i class="el-icon-plus"></i>
								<span>添加导航</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 底部按钮 -->
		<div class="footer">
			<el-button style="width:200px" @click="saveEdit(false)" :loading="loading">保存</el-button>
			<el-button type="primary" style="width:200px;margin-left:105px;" @click="saveEdit(true)" :loading="loading">发布</el-button>
		</div>

		<link-modal :showLiveTab="true" :visible="shopVisible" @change="dataChange" @cancel="shopVisible=false"></link-modal>
	</div>
</template>

<script>
	import apiList from '@/api/other';
	import draggable from "vuedraggable";
	import config from '@/config/index';
	import linkModal from '@/views/components/linkModal';
	export default {
		components: {
			draggable,
			linkModal
		},
		data() {
			return {
				themeColor: '#ff547b',
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				navEditShow: true,
				clickIndex: 0,
				loading: false,
				proLoading: false,
				cusLoading: false,
				navList: [],
				currrentIndex: null,
				shopVisible: false,
			}
		},
		created() {
			this.getList()
		},
		methods: {
			async getList() {
				this.loading = true
				try {
					let res = await apiList.bottomNavigationIndex()
					this.navList = res.Result
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			handleAddNav() {
				this.navList.push({
					Id: null,
					Name: '',
					ImgUrl: null,
					HighlightImgUrl: null,
					MenuType: null,
					PageName: null,
					PagePath: null,
					OtherData: null,
				})
				this.$forceUpdate()
			},
			handleDeleteNav(index) {
				this.navList.splice(index, 1)
				this.$forceUpdate()
			},
			deleteNavLink(index) {
				this.navList[index].MenuType = null
				this.navList[index].PageName = null
				this.navList[index].PagePath = null
			},
			//选择nav链接
			showNavModal(index) {
				this.currrentIndex = index;
				this.shopVisible = true;
			},
			dataChange(record) {
				if (record.type == "classify") {
					this.navList[this.currrentIndex].PageName = record.data.GroupName
					this.navList[this.currrentIndex].MenuType = 1
					this.navList[this.currrentIndex].PagePath = 'pages/productGroup/productGroup?id=' + record.data.Id
				} else if (record.type == "functional") {
					this.navList[this.currrentIndex].PageName = record.data.name
					this.navList[this.currrentIndex].MenuType = 2
					// 全部商品
					if (record.data.id == 1) {
						this.navList[this.currrentIndex].PagePath = 'pages/index/index'
					} else if (record.data.id == 2) {
						this.navList[this.currrentIndex].PagePath = 'pages/allProduct/allProduct'
					} else if (record.data.id == 3) {
						// 个人中心
						this.navList[this.currrentIndex].PagePath = 'pages/personalCenter/mine/mine'
					} else if (record.data.id == 4) {
						// 购物车
						this.navList[this.currrentIndex].PagePath = 'pages/shoppingCart/shoppingCart'
					} else if (record.data.id == 5) {
						// 积分中心
						this.navList[this.currrentIndex].PagePath = 'pages/pointsCenter/pointsCenter'
					} else if (record.data.id == 6) {
						// 每日签到
						this.navList[this.currrentIndex].PagePath = 'pages/userSign/userSign'
					} else if (record.data.id == 7) {
						// 客服
						this.navList[this.currrentIndex].PagePath = 'pages/customerService/customerService'
					} else if (record.data.id == 8) {
						// 订单列表
						this.navList[this.currrentIndex].PagePath = 'pages/order/myOrder/myOrder'
					} else if (record.data.id == 9) {
						// 分销礼包
						this.navList[this.currrentIndex].PagePath = 'pages/invitation/invitationCard/invitationCard'
					} else if (record.data.id == 10) {
						// 成为会员
						this.navList[this.currrentIndex].PagePath = 'pageA/pages/openSuccess/openSuccess'
					} else if (record.data.id == 11) {
						// 助力直播列表
						this.navList[this.currrentIndex].PagePath = 'pageA/pages/live/live'
					} else if (record.data.id == 12) {
						// 扫码购
						this.navList[this.currrentIndex].PagePath = 'pageA/pages/scan/scan'
					} else if (record.data.id == 13) {
						// 充值中心
						this.navList[this.currrentIndex].PagePath = 'pageA/pages/balanceRecharge/balanceRecharge'
					} else if (record.data.id == 14) {
						// 群分享
						this.navList[this.currrentIndex].PagePath = 'pageA/pages/groupShare/groupShare'
					}else if (record.data.id == 15) {
						// 群分享
						this.navList[this.currrentIndex].PagePath = 'pageA/pages/information/information'
					}else if (record.data.id == 16) {
            // 短视频带货
            this.navList[this.currrentIndex].PagePath = 'pageC/pages/shortVideos/record'
          }else if (record.data.id == 17) {
            // 积分码
            this.navList[this.currrentIndex].PagePath = 'pageA/pages/receive-point/receive-point'
          }else if (record.data.id == 18) {
            // 舌诊
             this.navList[this.currrentIndex].PagePath = 'pageC/pages/tongueDiagnosis/home'
          }else if (record.data.id == 19) {
						// 企店即创主页
						this.navList[this.currrentIndex].PagePath = 'pageC/pages/AIGC/home'
					}


				} else if (record.type == "product") {
					this.navList[this.currrentIndex].PageName = record.data.Name
					this.navList[this.currrentIndex].MenuType = 3
					this.navList[this.currrentIndex].PagePath = 'pages/detail/detail?id=' + record.data.Id

				} else if (record.type == "custom") {
					this.navList[this.currrentIndex].PageName = record.data.PageName
					this.navList[this.currrentIndex].MenuType = 4
					this.navList[this.currrentIndex].PagePath = 'pages/cusPage/cusPage?id=' + record.data.Id
				} else if (record.type == 'truntable') {
					this.navList[this.currrentIndex].PageName = record.data.Name
					this.navList[this.currrentIndex].MenuType = 6
					this.navList[this.currrentIndex].PagePath = 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
				} else if (record.type == 'live') {
					this.navList[this.currrentIndex].PageName = record.data.RoomName
					this.navList[this.currrentIndex].MenuType = 5
					this.navList[this.currrentIndex].OtherData = record
					this.navList[this.currrentIndex].PagePath =
						'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' + record.data.RoomId
				} else if (record.type == 'coupon') {
					this.navList[this.currrentIndex].PageName = record.data.CouponName
					this.navList[this.currrentIndex].MenuType = 7
					this.navList[this.currrentIndex].OtherData = record
					this.navList[this.currrentIndex].PagePath = 'pages/couponCenter/receive/receive?id=' + record.data.Id + '&uid=' +
						(record.data.uid || '')
				}else if (record.type == 'help') {
					this.navList[this.currrentIndex].PageName = record.data.Name
					this.navList[this.currrentIndex].MenuType = 8
					this.navList[this.currrentIndex].OtherData = record
					this.navList[this.currrentIndex].PagePath = 'pages/helpDetail/helpDetail?id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
				}else if (record.type == 'group') {
					this.navList[this.currrentIndex].PageName = record.data.ProductName
					this.navList[this.currrentIndex].MenuType = 9
					this.navList[this.currrentIndex].OtherData = record
					this.navList[this.currrentIndex].PagePath = 'pages/groupDetail/groupDetail?id=' + record.data.ProductId + '&fullId=' + record.data.ActivityFullId
				}else if (record.type == 'questionnaire') {
					this.navList[this.currrentIndex].PageName = record.data.Title
					this.navList[this.currrentIndex].MenuType = 10
					this.navList[this.currrentIndex].OtherData = record
					this.navList[this.currrentIndex].PagePath = 'pageA/pages/questionnaire/questionnaire?&referer=6&id=' + record.data.Id;
				}else if (record.type == 'taketest') {
          this.navList[this.currrentIndex].PageName = record.data.Name
          this.navList[this.currrentIndex].MenuType = 11
          this.navList[this.currrentIndex].OtherData = record
          this.navList[this.currrentIndex].PagePath = `pageC/pages/goodnessTest/home?id=` + record.data.Id;
        }else if (record.type == 'contentgroup') {
          this.navList[this.currrentIndex].PageName = record.data.GroupName
          this.navList[this.currrentIndex].MenuType = 12
          this.navList[this.currrentIndex].OtherData = record
          this.navList[this.currrentIndex].PagePath = `pageA/pages/information/informationGroup?id=${record.data.Id}`
        }else if (record.type == 'singlecontent') {
          this.navList[this.currrentIndex].PageName = record.data.Title
          this.navList[this.currrentIndex].MenuType = 13
          this.navList[this.currrentIndex].OtherData = record
          this.navList[this.currrentIndex].PagePath = `pageA/pages/information/detail?id=${record.data.Id}`
        }else if (record.type == 'helpPro') {
          this.navList[this.currrentIndex].PageName = record.data.ProductName
          this.navList[this.currrentIndex].MenuType = 14
          this.navList[this.currrentIndex].OtherData = record
          this.navList[this.currrentIndex].PagePath = `pageC/pages/helpPro/helpPro?id=${record.data.ProductId}&activityId=${record.data.ActivityFullId}`
        }
			},
			handleAvatarSuccess(res, file) {
				const isLt1M = file.size / 1024 < 1024;
				if (!isLt1M) {
					alert('图标大小请控制在1M以内');
					return false;
				}
				this.navList[this.currrentIndex].ImgUrl = res[0];
				this.$forceUpdate()
			},
			handleSelAvatarSuccess(res, file) {
				const isLt1M = file.size / 1024 < 1024;
				if (!isLt1M) {
					alert('图标大小请控制在1M以内');
					return false;
				}
				this.navList[this.currrentIndex].HighlightImgUrl = res[0];
				this.$forceUpdate()
			},
			handleMark(index, type) {
				this.currrentIndex = index;
			},
			handleNavShow() {
				this.navEditShow = !this.navEditShow;
			},
			//保存
			async saveEdit(IsRelease) {
				this.navList.map((t, i) => {
					if (t.MenuType != 5) {
						delete t.OtherData
					}
					t.SortAsc = i + 1
					return t
				})
				this.loading = true
				try {
					let res = await apiList.bottomNavigationSave({
						IsRelease: IsRelease,
						BottomNavigationList: this.navList
					})
					if (res.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: IsRelease ? '发布成功' : '保存成功'
						})

						this.getList()
					}
				} catch (e) {
					this.$message({
						showClose: true,
						type: 'error',
						message: IsRelease ? '发布失败' : '保存失败'
					})
					this.loading = false
				} finally {
					this.loading = false
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.main-content {
		overflow: auto;

		.content-box {
			display: flex;
			flex-direction: row;
			margin-bottom: 110px;
		}

		.browse-box {
			position: relative;
			flex: 0 0 auto;
			margin-left: 180px;
			width: 375px;
			height: 700px;
			background-color: #f5f5f5;
			box-shadow: 0 0 10px #dbdbdb;

			.top {
				width: 100%;
				height: 85px;
				position: relative;

				img {
					width: 100%;
					height: 100%;
				}

				.top-title {
					position: absolute;
					bottom: 0;
					width: 50%;
					height: 40px;
					left: 25%;
					line-height: 40px;
					text-align: center;
					font-size: 16px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.browse-border {
				border: 2px solid #409eff !important;
			}

			.nav {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				background: #fff;
				box-sizing: content-box;
				padding-bottom: 0;
				border-top: 1px solid #eee;

				.nav-footer {
					cursor: pointer;
					height: 60px;
					padding: 0 25px;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					background: #fff;
					font-size: 13px;
					color: #666;

					.item {
						font-size: 12px;
						text-align: center;
						flex: 1 1 auto;

						.img-box {
							display: inline-block;
							width: 24px;
							height: 24px;
							margin-bottom: 3px;
						}

						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
				}
			}
		}

		.edit-box {
			position: relative;

			.item {
				display: flex;
				flex-direction: row;
			}

			.icon-box {
				width: 30px;
				height: 30px;
				background-color: #fff;
				text-align: center;

				.el-icon-setting {
					margin-top: 7px;
				}
			}

			.dialog-box {
				width: 480px;
				padding: 0 20px;
				margin-left: 5px;
				background-color: #fff;
				position: relative;

				.close-box {
					.el-icon-close {
						position: absolute;
						top: 15px;
						right: 15px;
						font-size: 18px;
						color: #a1a1a1;
					}
				}

				.dialog-top {
					height: 60px;
					line-height: 60px;
					border-bottom: 1px solid #ddd;

					.dialog-title {
						font-size: 18px;
					}
				}

				.dialog-edit {
					line-height: 80px;
					display: flex;
					flex-direction: row;
					margin-left: 35px;
				}

				.dialog-edit-search {
					margin-bottom: 30px;
					display: flex;
					flex-direction: row;
					margin-left: 35px;
				}

				.show {
					align-items: center;
				}

				.tab-mark {
					margin-top: 10px;
					font-size: 14px;
					color: #909399;
				}

				.tab-item {
					margin-top: 25px;
					display: flex;
					flex-direction: row;
					overflow: hidden;

					.tab-edit {
						width: 400px;
						background: #f5f5f5;
						padding: 10px;
						line-height: 40px;

						.link {
							display: flex;
							flex-direction: row;
							line-height: 30px;

						}

						.icon {
							.icon-upload {
								display: inline-block;
								vertical-align: top;
								text-align: center;

								span {
									margin-right: 10px;
									font-size: 14px;
								}
							}
						}

						.select-link {
							margin-top: 10px;
							display: flex;
							flex-direction: row;
							line-height: 30px;

							i {
								cursor: pointer;
							}

							.link-name {
								cursor: pointer;

								.page-name {
									color: #409eff;
									font-size: 14px;
								}

								.el-icon-arrow-down,
								.el-icon-close {
									color: #409eff;
									margin-left: 10px;
									font-size: 18px;
								}

								display: flex;
								flex-direction: row;
								align-items: center;
							}
						}
					}
				}

				.tab-add {
					background: #f5f5f5;
					margin: 25px 0;
					line-height: 30px;
					padding: 0 10px;
					color: #409eff;
					width: 400px;

					.el-icon-plus {
						margin-right: 10px;
					}
				}

				.tab-show {
					// line-height: 60px;
					margin-bottom: 20px;
					display: flex;
					flex-direction: row;
					align-items: center;
				}
			}

			.item {
				display: flex;
				flex-direction: row;
			}

			.nav {
				top: 80px;
				position: absolute;
			}

			::v-deep .el-upload--picture-card {
				width: 80px;
				height: 80px;
				line-height: 80px;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}

			::v-deep .avatar-uploader-icon {
				width: 80px;
				height: 80px;
				line-height: 80px;
				text-align: center;
			}

			::v-deep .avatar {
				width: 80px;
				height: 80px;
			}
		}
	}

	.footer {
		text-align: center;
		line-height: 80px;
		position: fixed;
		bottom: 0px;
		width: 100%;
		background-color: #b3b3b3;
	}

	.dialog-table {
		overflow: hidden;
		margin-bottom: 50px;
	}

	.dialog-footer {
		text-align: center;
	}
</style>
